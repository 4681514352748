@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;

@keyframes fade-in-right{
    0%{
        opacity: 0;
        transform: translateX(-60px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes fade-in-left{
    0%{
        opacity: 0;
        transform: translateX(60px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

.about-container{
    // margin: 3rem 0;
    padding: 4.5rem 0;
    
    background-color: rgba(226, 217, 200, 0.432);
    position: absolute;
    @include tablet{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}
.about{
    animation: fade-in-right 3s;
    margin: 0 15%;
    text-align: left;
    line-height: 1.5rem;
    color: rgb(53, 38, 5);
    box-shadow: 3px 3px 3px 3px #66310ebd;
    background-color: rgba(241, 235, 214, 0.432);
    padding: 2rem;
    border-radius: 5px;
    height: 100%;
    width: 70%;
    @include tablet{
        width: 50%;
        background-color: rgba(255, 242, 221, 0.801);
        line-height: 1.8rem;
        margin: 0;
    }

    &-title{
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        @include tablet{
            display: none;
        }
        &__tablet{
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
            font-size: 3rem;
            padding-right: 3rem;
            color: rgb(46, 26, 3);
            animation: fade-in-left 3s;
            @include mobile{
                display: none;
            }
        }
    }

    &-text{
        @include tablet{
            font-size: 1.2rem;
        }
    }
}