@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;

.nav{
    // display: flex;
    // flex-direction: column;
    padding: 0.8rem;
    

    &-tablet{
        display: none;
        @include tablet{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0;
        }
    }

    &-logo{
        text-decoration: none;
        &__text{
            font-size: 1.5rem;
            color: $logo;
            padding-left: 0.8rem;
            cursor: pointer;
            @include tablet{
                font-size: 2rem;
                padding-top: 1rem;
            }
        }   
    }
    &-mobile{
        @include tablet{
            display: none;
        }
    }
    &-toggle{
        position: absolute;
        top: 0.8rem;
        right: 5%;
        cursor: pointer;
        color: $logo;
        
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // width: 1.8rem;
        // height: 1.3rem;
        @include tablet{
            display: none;
        }
        // &__line{
        //     height: 3px;
        //     width: 100%;
        //     background-color: rgb(132, 173, 211);
        //     border-radius: 10px;
        // }
    }
    &-list{
        flex-direction: column;
        position: absolute;
        list-style: none;
        text-align: center;
        margin: 0.8rem 0;
        padding: 0.5rem 0;
        right: 0;
        width: 100%;
        background-color: rgba(250, 238, 214, 0.836);
        @include tablet{
            background-color: rgba(255, 255, 255, 0);
            padding: 0;
            margin: 0;
        }
        
        &:active{
            display: flex;
        }
        @include tablet{
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
        }
        &__link{
            font-size: 1.1rem;
            text-align: center;
            cursor: pointer;
            padding: 0.5rem 0;
            @include tablet{
                padding: 0.5rem 1rem;
                &--border{
                    border: 2px solid rgb(49, 30, 4);
                    margin-right: 0.5rem;
                }   
            }
            &:hover{
                font-weight: bold;
                @include mobile{
                    // background-color: rgba(208, 230, 255, 0.726); 
                    font-weight: bold;  
                }
                
                @include tablet{
                    color: rgb(112, 50, 28);
                    transform: scale(1.1);
                }
            }
            &:active{
                
                @include tablet{
                  transform: scale(1);  
                }
            }
            
        }

    }
} 

