@use './variables.scss' as *;
@use './typography.scss' as *;

@mixin mobile {
    @media (max-width: $mobile) {
        @content
    }
};

@mixin tablet {
    @media (min-width: $tablet) {
        @content
    }
};

@mixin desktop {
    @media (min-width: $desktop) {
        @content
    }
};