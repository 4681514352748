@font-face {
    font-family: WorkSans;
    src: url(../assets/fonts/WorkSans-Bold.woff2) format(woff2),
    url(../assets/fonts/WorkSans-Bold.woff) format(woff);
    font-weight: 700;
}
@font-face {
    font-family: WorkSans;
    src: url(../assets/fonts/WorkSans-SemiBold.woff2) format(woff2),
    url(../assets/fonts/WorkSans-SemiBold.woff) format(woff);
    font-weight: 600;
}
@font-face {
    font-family: WorkSans;
    src: url(../assets/fonts/WorkSans-Medium.woff2) format(woff2),
    url(../assets/fonts/WorkSans-Medium.woff) format(woff);
    font-weight: 500;
}
@font-face {
    font-family: WorkSans;
    src: url(../assets/fonts/WorkSans-Regular.woff2) format(woff2),
    url(../assets/fonts/WorkSans-Regular.woff) format(woff);
    font-weight: 400;
}
@font-face {
    font-family: WorkSans;
    src: url(../assets/fonts/WorkSans-Light.woff2) format(woff2),
    url(../assets/fonts/WorkSans-Light.woff) format(woff);
    font-weight: 300;
}
@font-face {
    font-family: WorkSans;
    src: url(../assets/fonts/WorkSans-ExtraLight.woff2) format(woff2),
    url(../assets/fonts/WorkSans-ExtraLight.woff) format(woff);
    font-weight: 200;
}