@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;

@keyframes fade-in-down{
    0%{
        opacity: 0;
        transform: translateY(-50px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
} 
@keyframes fade-in-right{
    0%{
        opacity: 0;
        transform: translateX(-60px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes fade-in-left{
    0%{
        opacity: 0;
        transform: translateX(60px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
.hero-container{
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet{
        // justify-content: space-around;

    }
    // background-color: #abdae959;
    // background-color: rgba(137, 183, 209, 0.336);
    // height: 90vh;
    // @include tablet{
    //     background-color: rgba(169, 172, 173, 0.295);
    //     height: 85vh
    // }
}
.hero{
    text-align: center;
    padding: 0;
    width: 90%;
    color: rgb(255, 255, 255);
    position: absolute;
    @include tablet{
        display: flex;
        flex-direction: column;
        text-align: left;
        animation: fade-in-right 2s;
    }
    @include desktop{
        padding-left: 6%;
    }
    &-title{
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
        @include tablet{
            padding-bottom: 0;
        }
        
        &__left{
            font-size: 1.7rem;
            padding-top: 45%;
            // padding-bottom: 1.5rem;
            animation: fade-in-down 1.5s; 
            @include tablet{
                display: none;
            }
            @include desktop{
                display: none;
            }
        }
        &__right{
            font-size: 1.7rem;
            padding-top: 45%;
            // padding-bottom: 1.5rem;
            animation: fade-in-down 2s; 
            
            @include tablet{
                display: none;
            }
            @include desktop{
                display: none;
            }
        }
        &__full{
            display: none;
            @include tablet{
                display: flex;
                font-size: 3rem;
                animation: fade-in-down 1s; 
            }
            
        }      
    }
    &-content{
        margin-top: 2rem;
        padding-top: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        @include tablet{
            align-items: flex-start;
            padding: 0;
            width: 50%;
        }
        
        &__text{
            font-size: 1.1rem;
            padding-bottom: 1rem;
            animation: fade-in-down 3s;
            line-height: 1.5rem;
            @include tablet{
                font-size: 1.1rem; 
                padding-bottom: 2rem;
            }
            @include desktop{
                font-size: 1.5rem;
            }
        }
    }
    // &-button{
    //     height: 2rem;
    //     border: none;
    //     border-radius: 2px;
    //     background-color: rgba(218, 167, 138, 0.973);
    //     cursor: pointer;
    //     animation: fade-in-down 2s;
    //     &:hover{
    //         background-color: rgba(255, 255, 255, 0);
    //         border: 2px solid rgba(218, 167, 138, 0.973);
    //         transform: scale(1.1);
    //     }
    //     &:active{
    //         background-color: rgba(218, 167, 138, 0.973);
    //         transform: scale(1);
    //     }
    //     @include tablet{
    //         height: 2.2rem;
    //         width: 5rem;
            
    //     }
    //     @include desktop{
    //         height: 2.5rem;
    //         width: 8rem;
    //     }
    //     &__text{
    //         color: rgb(255, 247, 244);
    //         @include tablet{
    //             font-size: 0.9rem;
    //             color: rgb(100, 94, 91);
    //         }
    //         @include desktop{
    //             font-size: 1.1rem;
    //         }
    //     }
    // }
    &-img{
        display: flex;
        justify-content: center;
        margin: 2rem 0; 
        @include tablet{
            justify-content: flex-end;
            animation: fade-in-left 2s;
            margin-right: 2rem;
            margin-bottom: 0;
        }
        @include desktop{
            padding-left: 15%;
            margin-right: 0;
        }
        &__img{
            width: 80%;
            animation: fade-in-down 1s; 
            @include tablet{ 
                width: 40%;
            }
        }
       
    }
}