@keyframes animate {
    0%{
        transform: scale(0) translateY(0) rotate(0);
        opacity: 1;
    }
    100%{
        transform: scale(1.3) translateY(-90px) rotate(360deg);
        opacity: 0;
    }
}

html{
    background: linear-gradient(90deg, rgb(145, 121, 99)0%, rgb(218, 192, 163)35%, rgb(221, 185, 144)100%);
    position: absolute;
    height: 100%;
    width: 100%;
    // overflow: hidden;
    
}
.box div{
    position: absolute;
    width: 3rem; //3.75
    height: 3rem;
    background-color: transparent;
    border: 0.3rem solid rgba(133, 78, 7, 0.8);
}
.box div:nth-child(1){
    top: 60%;
    left: 85%;
    animation: animate 10s linear infinite;
}
.box div:nth-child(2){
    top: 20%;
    left: 30%;
    animation: animate 7s linear infinite;
}
.box div:nth-child(3){
    top: 35%;
    left: 20%;
    animation: animate 9s linear infinite;
}
.box div:nth-child(4){
    top: 20%;
    left: 60%;
    animation: animate 10s linear infinite;
}
.box div:nth-child(5){
    top: 67%;
    left: 10%;
    animation: animate 6s linear infinite;
}
.box div:nth-child(6){
    top: 80%;
    left: 70%;
    animation: animate 12s linear infinite;
}
.box div:nth-child(7){
    top: 90%;
    left: 30%;
    animation: animate 10s linear infinite;
}
.box div:nth-child(8){
    top: 90%;
    left: 85%;
    animation: animate 8s linear infinite;
}
.box div:nth-child(9){
    top: 90%;
    left: 12%;
    animation: animate 15s linear infinite;
}